<template>
  <!-- 批量打卡 -->
  <el-dialog
    :visible.sync="dialogVisible"
    width="650px"
    :show-close="false"
    class="export-dialog"
    :close-on-click-modal="false"
  >
    <div slot="title">
      <div class="dialog-head" style="border-bottom: 0">批量打卡</div>
    </div>
    <div class="autocomplete">
      <el-autocomplete
        v-model="searchQuestionKey"
        :fetch-suggestions="querySearch"
        :trigger-on-focus="false"
        placeholder="输入题号，题号间用英文逗号隔开，按回车确认"
        :popper-append-to-body="false"
        @input="searchQuestions"
        @keypress.native.enter="selectQuestions"
      >
        <template slot-scope="{ item }">
          <div class="auto-cell" v-show="questions.length != 0">
            <div class="auto-cell-hd">
              <span class="name">{{ item.questionName }}</span>
            </div>
            <div class="el-icon-check"></div>
          </div>
        </template>
      </el-autocomplete>
      <div class="questions">
        <el-tag
          v-for="(question, index) in selectedQuestions"
          :key="index"
          closable
          @close="deleteQuestion(index)"
        >
          {{ question.questionName }}
        </el-tag>
      </div>
    </div>
    <div slot="footer" class="dialog-foot">
      <el-button
        type="warning"
        size="small"
        class="btn warn"
        style="background: #fa6400"
        @click="saveClocks"
        >保 存</el-button
      >
      <el-button
        type="info"
        size="small"
        class="btn text"
        @click="dialogVisible = false"
        >取 消</el-button
      >
    </div>
  </el-dialog>
</template>
<script>
import { searchQuestions, saveClocks } from "../../../service/punchClock.js";
export default {
  name: "importQuestions",
  props: {
    visible: {
      type: Boolean,
      default: false,
      required: true,
    },
  },

  data() {
    return {
      questions: [],
      searchQuestionKey: "",
      selectedQuestions: [],
      timeout: null,
    };
  },
  computed: {
    dialogVisible: {
      get() {
        return this.visible;
      },
      set(val) {
        this.$emit("changeImportQuestionVisible", val);
      },
    },
  },
  methods: {
    querySearch(queryString, cb) {
      clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        cb(queryString == "" ? [] : this.questions);
      }, 1000);
    },
    searchQuestions() {
      searchQuestions(this.searchQuestionKey).then((res) => {
        if (res.success) {
          this.questions = res.result;
        }
      });
    },
    selectQuestions() {
      // this.questions
      const selectedQuestionsArray = this.selectedQuestions.map((item) => {
        return item.questionNum;
      });
      console.log(selectedQuestionsArray);
      this.questions.map((item) => {
        if (selectedQuestionsArray.indexOf(item.questionNum) == -1) {
          this.selectedQuestions.push(item);
        }
      });
      this.questions = [];
      this.searchQuestionKey = "";
    },
    deleteQuestion(index) {
      this.selectedQuestions.splice(index, 1);
    },
    saveClocks() {
      const data = {
        courseSectionId: this.selectedQuestions.map((item) => {
          return item.questionNum;
        }),
      };
      saveClocks(data).then((res) => {
        if (res.success) {
          this.$message.success("批量打卡成功!");
          this.dialogVisible = false;
          this.$emit("updateMyClocks");
          this.$bus.$emit("updateStatistics", "update");
        } else {
          this.$message.error(res.message);
        }
      });
    },
  },
  mounted() {},
};
</script>
<style scoped>
.dialog-head {
  padding: 15px 12px;
  border-bottom: 1px solid rgba(235, 236, 241, 0.91);
  font-size: 18px;
  color: #34495e;
  position: relative;
}

.dialog-head::before {
  position: absolute;
  content: "";
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 3px;
  height: 18px;
  background: #fa6400;
  border-radius: 2px;
}
.questions {
  height: 400px;
  overflow-y: auto;
}

.autocomplete .el-autocomplete {
  display: block;
}

.auto-cell {
  display: flex;
  align-items: center;
  justify-content: space-between;
  line-height: 34px;
}
.export-dialog .export-tit {
  text-align: center;
  font-size: 16px;
  color: #34495e;
  line-height: 24px;
  padding-bottom: 12px;
}
.export-dialog ::v-deep .el-dialog__header {
  padding: 20px 35px 20px 45px;
}

.export-dialog .dialog-head {
  padding: 8px 12px;
}

.export-dialog ::v-deep .el-dialog__body {
  padding: 0 35px;
}

.export-dialog ::v-deep .el-dialog__footer {
  padding: 35px 20px;
}
.dialog-foot {
  text-align: center;
}

.dialog-foot .btn {
  min-width: 88px;
}
.questions {
  padding-top: 10px;
}
.questions .el-tag {
  width: 100%;
  margin-bottom: 10px;
}
::v-deep .el-tag {
  position: relative;
}
::v-deep .el-tag .el-icon-close {
  position: absolute;
  top: 50%;
  right: 5px;
  transform: translateY(-50%);
}
::v-deep .el-button + .el-button {
  margin-left: 40px;
}
</style>