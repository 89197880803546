<template>
  <!-- 打卡主页 -->
  <div id="app" style="background: #f4f4f4">
    <div class="main">
      <div class="cson-container clearfix">
        <div class="content">
          <div class="content-head">
            <div class="content-title">
              刷题打卡
              <div class="content-line"></div>
              <span class="content-subtext">分类刷题，更高效</span>
            </div>
          </div>
          <div class="clearfix">
            <div class="comment-lt">
              <div class="dk-calendar">
                <div class="dk-head">
                  <div class="sum-days">打卡总天数 {{ clockDays }}</div>
                  <div class="dk-status">
                    <div class="dk-status-item active">
                      <span class="is-cir"></span>已打卡
                    </div>
                  </div>
                </div>
                <div class="dk-body">
                  <!--日历-->
                  <FunctionalCalendar
                    v-if="getDates"
                    :markedDates="markedDates"
                    dateFormat="yyyy-mm-dd"
                    @dayClicked="dayClicked"
                  >
                  </FunctionalCalendar>

                  <!-- 刷题历史记录 -->
                  <historyTest
                    :visible="historyVisible"
                    @changeHistoryVisible="(val) => (historyVisible = val)"
                    :choosedDay="choosedDay"
                  ></historyTest>
                </div>
              </div>
            </div>
            <targetPanel
              :data="privateTarget"
              @editTargetDialog="editTargetDialog"
            ></targetPanel>
          </div>
          <clockTabPanel></clockTabPanel>
        </div>

        <div class="aside">
          <div class="aside-top"></div>
          <div class="aside-panel userInfo">
            <userInfoPanel></userInfoPanel>
          </div>

          <myGroupPanel></myGroupPanel>

          <groupPanel></groupPanel>
          <groupTestPanel></groupTestPanel>
          <privateTestPanel></privateTestPanel>
          <div class="aside-course">
            <router-link to="">
              <img src="../../../assets/punch/course1.png" />
            </router-link>
          </div>
          <div class="aside-course">
            <router-link to="">
              <img src="../../../assets/punch/course2.png" />
            </router-link>
          </div>
        </div>
      </div>
    </div>
    <!-- 页面初始弹出 -->
    <targetInit
      :visible="targetDialogVisible"
      @changeTargetDialogVisible="(val) => (targetDialogVisible = val)"
      :type="targetSetType"
      :data="privateTarget"
    ></targetInit>
  </div>
</template>

<script>
import { FunctionalCalendar } from "vue-functional-calendar";

import { mapState } from "vuex";
import {
  getTarget,
  getClockDays,
  getClockDates,
} from "../../../service/punchClock.js";
import userInfoPanel from "../panels/userInfoPanel";
import myGroupPanel from "../panels/myGroupPanel";
import groupPanel from "../panels/groupPanel";
import groupTestPanel from "../panels/groupTestPanel";
import privateTestPanel from "../panels/privateTestPanel";
import targetInit from "./targetInit";
import targetPanel from "./targetPanel";
import historyTest from "./historyTest";
import clockTabPanel from "./clockTabPanel";
export default {
  name: "punchClockInit",
  components: {
    FunctionalCalendar,
    userInfoPanel,
    myGroupPanel,
    groupPanel,
    groupTestPanel,
    privateTestPanel,
    targetInit,
    targetPanel,
    historyTest,
    clockTabPanel,
  },
  data() {
    return {
      targetSetType: "init",
      privateTarget: {},
      targetDialogVisible: false,
      historyVisible: false,
      clockDays: 0,
      getDates: false,
      markedDates: [],
      choosedDay: "",
      //
    };
  },
  created() {},
  methods: {
    getTarget() {
      getTarget().then((res) => {
        if (res.success) {
          console.log(res);
          if (res.result.targetDay == 0 && res.result.targetNum == 0) {
            this.targetDialogVisible = true;
          } else {
            this.privateTarget = res.result;
          }
        }
      });
    },

    editTargetDialog() {
      this.targetSetType = "edit";
      this.targetDialogVisible = true;
    },
    getClockDetail() {
      getClockDays().then((res) => {
        if (res.success) {
          this.clockDays = res.result;
        }
      });
      getClockDates(
        `${new Date().getFullYear()}-${
          new Date().getMonth() + 1 < 10
            ? "0" + (new Date().getMonth() + 1)
            : new Date().getMonth() + 1
        }-01`
      ).then((res) => {
        if (res.success) {
          this.markedDates = res.result.map((item) => {
            return {
              date: item.date,
              class:
                item.count < 5
                  ? `dk-already-level1 marked count-${item.count}-nums`
                  : 5 < item.count < 10
                  ? `dk-already-level2 marked count-${item.count}-nums`
                  : 10 < item.count < 15
                  ? `dk-already-level3 marked count-${item.count}-nums`
                  : `dk-already-level4 marked count-${item.count}-nums`,
              count: item.count,
            };
          });
          this.getDates = true;
          this.$nextTick(() => {
            this.addEventListener();
          });
        }
      });
    },
    dateFormat(thisDate, fmt) {
      var o = {
        "M+": thisDate.getMonth() + 1,
        "d+": thisDate.getDate(),
        "h+": thisDate.getHours(),
        "m+": thisDate.getMinutes(),
        "s+": thisDate.getSeconds(),
        "q+": Math.floor((thisDate.getMonth() + 3) / 3),
        S: thisDate.getMilliseconds(),
      };
      if (/(y+)/.test(fmt))
        fmt = fmt.replace(
          RegExp.$1,
          (thisDate.getFullYear() + "").substr(4 - RegExp.$1.length)
        );
      for (var k in o)
        if (new RegExp("(" + k + ")").test(fmt))
          fmt = fmt.replace(
            RegExp.$1,
            RegExp.$1.length == 1
              ? o[k]
              : ("00" + o[k]).substr(("" + o[k]).length)
          );
      return fmt;
    },

    dayClicked(day) {
      if (
        this.markedDates
          .map((item) => {
            return item.date;
          })
          .indexOf(day.date) == -1
      ) {
        return;
      }
      this.choosedDay = this.dateFormat(new Date(day.date), "yyyy-MM-dd");
      this.historyVisible = true;
    },

    addEventListener() {
      const markedDates = document.querySelectorAll(".marked");
      markedDates.forEach((markedDate) => {
        markedDate.addEventListener("mouseenter", function (e) {
          e.target.title = `刷题量：${
            e.target.className.split("count-")[1].split("-nums")[0]
          }`;
        });
      });
    },
  },
  mounted() {
    this.getTarget();
    this.getClockDetail();
    this.$bus.$on("updateStatistics", (e) => {
      if (e == "update") {
        this.getDates = false;
        this.getTarget();
        this.getClockDetail();
      }
    });
  },
  computed: { ...mapState(["userInfo"]) },
};
</script>

<style lang="css" scoped>
.content {
  width: 890px;
  float: left;
}

.aside {
  width: 300px;
  float: right;
}

.content-head {
  padding: 20px 0;
}

.content-title {
  display: flex;
  font-weight: bold;
  align-items: center;
  font-size: 18px;
  color: #0075f6;
  line-height: 27px;
}

.content-subtext {
  font-size: 16px;
  line-height: 24px;
  font-weight: normal;
}

.content-line {
  width: 2px;
  height: 28px;
  margin: 0 13px;
  background: rgba(136, 148, 171, 0.2);
}

.comment-lt {
  float: left;
  width: 458px;
  border-radius: 4px;
  overflow: hidden;
}

.panel-title {
  position: relative;
  font-weight: 500;
  color: #34495e;
  font-size: 14px;
  padding-left: 11px;
  line-height: 20px;
}

.panel-title::before {
  position: absolute;
  content: "";
  width: 3px;
  height: 20px;
  background: #fa6400;
  border-radius: 2px;
  left: 0;
  top: 0;
}

.panel {
  padding: 0 20px;
}

.panel-head {
  padding: 17px 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.bread-tab {
  display: inline-block;
  padding: 0 2px;
}

.bread-tab.active {
  color: #fa6400;
}

.bread-tabs-head {
  color: rgba(52, 73, 94, 0.7);
  line-height: 20px;
  font-size: 14px;
}

.bread-tabs {
  float: right;
  margin-right: 40px;
}

.left {
  float: left;
}

.team-img {
  font-size: 0;
}

.team-cell__hd {
  width: 200px;
  margin-right: 17px;
  float: left;
}

.team-cell {
  padding: 24px 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.team-cell-title {
  color: #34495e;
  line-height: 24px;
  font-size: 16px;
  margin-bottom: 10px;
}

.push-time {
  color: rgba(0, 0, 0, 0.5);
  line-height: 18px;
  font-size: 12px;
  margin-left: 12px;
}

.team-cell-desc {
  font-size: 14px;
  color: rgba(52, 73, 94, 0.7);
  line-height: 20px;
  margin-bottom: 25px;
}

.team-cell-desc .el-link {
  margin-left: 30px;
}

.team-cell .el-link {
  color: #fa6400;
}

.team-cell__bd {
  position: relative;
  margin-left: 217px;
}

.team-cell-info .comment-user {
  color: #34495e;
}

.team-cell-info .comment-user .comment-number {
  color: #34495e;
  opacity: 0.5;
  margin-left: 15px;
}

.team-cell-info .comment-user .comment-number .iconfont {
  font-size: 12px;
}

.team-cell__footer {
  padding: 15px 20px 0;
}

.team-cell__ed {
  float: left;
}

.team-cell__block {
  position: relative;
  margin-left: 44px;
}

.team-cell__block .el-textarea ::v-deep .el-textarea__inner {
  background: rgba(235, 236, 241, 0.43);
  border-radius: 2px;
  border: 1px solid #979797;
}

.team-cell__bts {
  text-align: right;
  padding: 10px 0;
}

.team-cell__bts .btn {
  font-size: 14px;
  border-radius: 2px;
  width: 64px;
  line-height: 28px;
  border-color: transparent;
  margin-left: 10px;
}

.btn-default {
  color: #34495e;
  background-color: rgba(136, 148, 171, 0.4);
  font-size: 14px;
}

.btn-warn {
  color: #fff;
  background-color: rgba(250, 100, 0, 1);
  font-size: 14px;
}

.team-comment__ava {
  font-size: 0;
}

.team-comment {
  display: flex;
}

.team-comment__ava img {
  width: 24px;
  height: 24px;
  border-radius: 100%;
}

.team-comment__hd {
  width: 24px;
  margin-right: 6px;
}

.team-comment__bd {
  flex: 1;
}

.team-comment__name {
  font-size: 14px;
  font-weight: bold;
  color: #34495e;
  line-height: 20px;
}

.team-comment__jj {
  font-weight: 400;
  color: #898989;
  line-height: 18px;
  font-size: 12px;
  margin-bottom: 5px;
}

.team-comment__desc {
  color: rgba(52, 73, 94, 0.7);
  line-height: 20px;
  font-size: 14px;
  margin-bottom: 6px;
}

.team-comment__time {
  font-size: 12px;
  color: #898989;
  line-height: 18px;
  margin-bottom: 8px;
}

.team-comment__number {
  color: rgba(0, 0, 0, 0.85);
  line-height: 18px;
  font-size: 12px;
}

.my-dk {
  line-height: 24px;
  font-size: 14px;
  background: #fa6400;
  border-radius: 16px;
  width: 86px;
  text-align: center;
  border-color: transparent;
  color: #fff;
}

.aside-top {
  text-align: right;
  padding: 20px 0;
  min-height: 68px;
}

.aside-lt {
  float: left;
}

.aside-title {
  color: #fa6400;
  line-height: 22px;
  font-size: 14px;
}

.aside-more {
  font-weight: 500;
  color: #8894ab;
  line-height: 22px;
  font-size: 12px;
  text-decoration: underline;
}

.aside-rt {
  float: right;
}

.aside-panel {
  background-color: #fff;
  padding: 0 15px;
  margin-bottom: 10px;
  border-radius: 4px;
}

.aside-head {
  padding: 24px 0 6px 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.panel {
  background-color: #fff;
  margin-top: 10px;
}

.group-cell {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.group-cells {
  padding: 18px 0;
}

.group-cell__hd {
  width: 42px;
  height: 42px;
  background: #ececec;
  border-radius: 4px;
  border: 1px solid #cecece;
  margin-right: 14px;
}

.group-cell__bd {
  flex: 1;
}

.jion-btn {
  color: #fa6400;
  font-size: 12px;
  background: rgba(250, 100, 0, 0.1);
  border-radius: 2px;
  line-height: 20px;
  text-align: center;
  width: 48px;
  border-color: transparent;
  cursor: pointer;
}

.group-name {
  color: #34495e;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 4px;
  color: #34495e;
}

.group-trophy {
  color: #b6daff;
  font-size: 12px;
  margin-left: 15px;
}

.group-data {
  line-height: 18px;
}

.group-data span {
  color: rgba(52, 73, 94, 0.7);
  font-size: 12px;
  margin-left: 10px;
}

.el-table--mini ::v-deep td,
.el-table--mini ::v-deep th {
  padding: 0;
  border: 0;
  color: rgba(0, 0, 0, 0.5);
}

.el-table--mini ::v-deep th {
  background: rgba(0, 117, 246, 0.1);
  color: #34495e;
}

.el-table--mini ::v-deep .el-table__row td:first-child .cell div {
  width: 16px;
  height: 16px;
  background: #b6daff;
  line-height: 16px;
  text-align: center;
  border-radius: 2px;
  color: #fff;
  margin: 0 auto;
}

.el-table--mini ::v-deep .el-table__row:first-child td:first-child .cell div {
  background: #ff5a43;
}

.el-table--mini ::v-deep .el-table__row:nth-child(2) td:first-child .cell div {
  background: #44d7b6;
}

.el-table--mini ::v-deep .el-table__row:nth-child(3) td:first-child .cell div {
  background: #f8c51c;
}

.el-table--mini ::v-deep .el-table__body-wrapper {
  padding: 10px 0;
}

.el-table::before {
  background-color: transparent;
}

.aside-course {
  font-size: 0;
  margin-bottom: 10px;
}

.aside-course img {
  width: 100%;
  height: 130px;
}

.el-dialog__wrapper ::v-deep .el-dialog__body {
  padding: 20px 60px 50px;
}

.dialog-title .bread-tabs-head {
  font-size: 18px;
  line-height: 32px;
}

.bread-tab-line {
  display: inline-block;
}

.dialog-header {
  padding: 0 50px 12px;
  border-bottom: 1px solid rgba(235, 236, 241, 0.91);
}

.dialog-title {
  float: left;
}

.dialog-title .bread-tab-line {
  margin: 0 6px;
}

.dialog-search {
  float: right;
}

.icon-up {
  color: #ff5a43 !important;
}

.icon-down1 {
  color: #44d7b6 !important;
}

.my-panel {
  background-color: #fff;
  padding: 20px 30px;
  margin-bottom: 10px;
}

.my-panel__hd {
  border-bottom: 1px dashed rgba(136, 148, 171, 0.2);
  text-align: center;
  padding: 10px 0;
}

.my-avatar img {
  width: 32px;
  height: 32px;
  border-radius: 100%;
}

.my-name {
  color: #34495e;
  font-size: 14px;
  line-height: 24px;
}

.my-val {
  font-size: 16px;
  color: #fa6400;
  text-align: center;
  line-height: 18px;
}

.my-label {
  font-size: 12px;
  color: #34495e;
  text-align: center;
  line-height: 18px;
}

.my-grid {
  padding: 10px 0;
}

.my-history {
  color: #fa6400;
  font-size: 12px;
  text-align: center;
  text-decoration: underline;
  line-height: 22px;
  margin-top: 10px;
}

.group-justify {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.group-justify span {
  margin: 0;
}

.group-cell-border {
  padding: 6px 16px;
  border-radius: 2px;
  border: 1px solid #e0e0e0;
  margin-bottom: 15px;
}

.el-carousel {
  padding: 30px 0;
}

.warn {
  background-color: #fa6400 !important;
}

.grey {
  background-color: #8894ab !important;
}

.dialog-foot .el-button + .el-button {
  margin-left: 40px;
}

.dialog-body {
  padding: 0 50px;
}

autocomplete.progress-item-text {
  font-weight: 500;
  color: #34495e;
  font-size: 18px;
}

.progress-item-text span {
  color: rgba(52, 73, 94, 0.7);
  font-size: 14px;
}

.progress-item-val {
  display: flex;
  align-items: center;
  padding: 22px 0;
}

.progress-item-val .el-button-group {
  margin-left: 20px;
  display: flex;
  align-items: center;
}

.progress-item-val .el-button-group .el-button {
  background-color: rgba(237, 238, 242, 1);
}

.progress-item-val .el-button-group .el-input ::v-deep .el-input__inner {
  height: 28px;
  line-height: 28px;
  width: 58px;
  text-align: center;
  border-radius: 0;
}

.set-target {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  color: #34495e;
  line-height: 24px;
  margin-top: 30px;
}

.set-target-item {
  margin: 0 5px;
  display: flex;
  white-space: nowrap;
}

.set-target-item ::v-deep .el-input .el-input__inner {
  background: rgba(250, 100, 0, 0.1);
  font-size: 18px;
  line-height: 24px;
  text-align: center;
  color: #fa6400;
  height: 24px;
  max-width: 50px;
  padding: 0 2px;
  border: 0;
  border-radius: 2px;
}

.create-box {
  text-align: center;
  padding-top: 18px;
}

.create-box .btn {
  background: #ffffff;
  border-radius: 2px;
  border: 1px solid #fa6400;
  color: #fa6400;
}

.dynamic-form-item .el-button {
  width: 90px;
}

.dynamic-form-item ::v-deep .el-textarea__inner {
  background-color: rgba(235, 236, 241, 0.43);
}

.dynamic-form-item ::v-deep .el-input__inner {
  background-color: rgba(235, 236, 241, 0.43);
}

.dynamic-form-item + .dynamic-form-item {
  margin-top: 10px;
}

.push-btn {
  background-color: #fa6400;
  border-color: #fa6400;
  color: #fff;
  float: right;
}

.dynamic-form-item .el-select {
  width: 125px;
}

.dynamic-form-item ::v-deep .el-select .el-input__inner {
  background-color: #fff;
}

.bg-line {
  margin: 0 -40px;
  height: 20px;
  background: #f8f8f8;
}

.dynamic-form {
  padding-bottom: 40px;
  border-radius: 4px;
}

.avatar-uploader {
  width: 60px;
  height: 60px;
  border: 1px solid #d9d9d9;
  border-radius: 100%;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  text-align: center;
}

.avatar-uploader ::v-deep .el-upload {
  width: 60px;
  height: 60px;
  display: block;
}

.avatar-uploader-icon {
  font-size: 12px;
  color: #8894ab;
  width: 60px;
  height: 60px;
  line-height: 60px;
  font-style: normal;
  text-align: center;
}

.avatar {
  width: 60px;
  height: 60px;
  display: block;
}

.group-avater .el-form-item ::v-deep .el-form-item__label {
  line-height: 60px;
}

.creat-form .el-form ::v-deep .el-form-item__label {
  line-height: 60px;
}

.creat-form .el-form ::v-deep .el-form-item__content {
  padding-top: 10px;
}

.group-avater .el-form-item ::v-deep .el-form-item__content {
  padding-top: 0;
}

.creat-form .progress-item-val {
  padding: 0;
}

.creat-form .progress-item-text {
  min-width: 90px;
  text-align: right;
  font-size: 12px;
  color: #34495e;
}

.creat-form .el-form ::v-deep .el-form-item {
  margin-bottom: 0;
}

.creat-dialog .dialog-head {
  border-bottom: 0;
}

.creat-dialog.el-dialog__wrapper ::v-deep .el-dialog__body {
  padding: 20px 0;
}

.creat-dialog.el-dialog__wrapper ::v-deep .el-dialog__footer {
  padding-bottom: 40px;
  padding-top: 20px;
}

.creat-dialog.el-dialog__wrapper ::v-deep .el-dialog {
  padding: 0 110px;
}

.success-dialog .dialog-title {
  font-size: 18px;
  color: #34495e;
  text-align: center;
  float: unset;
}

.success-welcome {
  font-size: 14px;
  color: #8894ab;
  text-align: center;
}

.dialog-user {
  color: #0075f6;
  text-decoration: underline;
}

.success-dialog ::v-deep .el-dialog {
  padding: 0 110px;
}

.success-dialog ::v-deep .el-dialog__body {
  padding: 0;
}

.dialog-links {
  display: flex;
  align-items: center;
}

.dialog-links .el-button {
  margin-left: 15px;
}

.success-dialog ::v-deep .el-dialog__footer {
  padding-bottom: 50px;
}

.success-dialog ::v-deep .el-dialog__header {
  padding-top: 50px;
  padding-bottom: 35px;
}

.success-dialog .el-form-item {
  margin-bottom: 16px;
}

.success-welcome {
  width: 70%;
  margin: 0 auto;
  margin-bottom: 22px;
}

.note {
  background: rgba(235, 236, 241, 0.41);
  /* 	padding: 25px; */
  display: flex;
  align-items: center;
}

.history-dialog-flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.history-dialog-flex .el-input {
  width: 200px;
}

.history-dialog-flex .el-input ::v-deep .el-input__inner {
  border-radius: 16px;
}

.history-dialog-flex .dialog-head {
  border-bottom: 0;
  padding: 0 12px;
}

.history-dialog ::v-deep .el-dialog {
  padding: 0 60px;
}

.history-dialog-head {
  padding: 0 50px;
  border-bottom: 1px solid #ddd;
}

.history-dialog-head ::v-deep .el-input__inner {
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.25) inset;
}

.history-dialog ::v-deep .el-dialog__body {
  padding: 0;
}

.history-brush {
  text-align: center;
  color: #fa6400;
  font-size: 16px;
  line-height: 24px;
  padding: 20px 0;
}

.exp-btn {
  width: 80px !important;
  font-size: 14px !important;
  background-color: rgba(235, 236, 241, 0.6);
  border: 0;
  color: #000000;
  opacity: 0.85;
}

.exp-btn .iconfont {
  font-size: 14px;
  margin-right: 4px;
}

::v-deep .el-autocomplete-suggestion__wrap {
  max-height: 360px;
  background: rgba(235, 236, 241, 0.41);
  padding: 0;
}

::v-deep .el-popper {
  margin: 0;
}

::v-deep .popper__arrow {
  display: none;
}

.uploadImage {
  display: none;
}

.dk-calendar {
  background-color: #fff;
  padding: 0 10px;
}

.dk-head {
  padding: 20px 25px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.sum-days {
  font-size: 14px;
  color: #0075f6;
}

.dk-status {
  display: flex;
  align-items: center;
}

.is-cir {
  display: inline-block;
  width: 6px;
  height: 6px;
  background: rgba(52, 73, 94, 0.7);
  border-radius: 6px;
  margin-right: 2px;
}

.dk-status-item {
  font-size: 12px;
  color: #34495e;
  margin-left: 20px;
  display: flex;
  align-items: center;
}

.dk-status-item.active {
  color: #46c8a4;
}

.dk-status-item.active .is-cir {
  background-color: rgba(70, 200, 164, 1);
}

.dk-body {
  min-height: 329px;
}

.dk-body ::v-deep .wh_content_item {
  color: #000000;
}

.dk-body ::v-deep .wh_content_item .wh_other_dayhide {
  color: #aaaaaa;
}

.dk-body ::v-deep .wh_top_changge {
  background: rgba(250, 100, 0, 0.1);
}

.dk-body ::v-deep .wh_content_li {
  color: #fa6400;
}

.dk-body ::v-deep .wh_jiantou1,
.dk-body ::v-deep .wh_jiantou2 {
  border-color: #fa6400;
  overflow: hidden;
}

.dk-body ::v-deep .wh_content_all {
  background-color: #fff;
}

.dk-body ::v-deep .wh_content_item {
  height: 28px;
  margin: 5px 0;
  width: 14%;
}

.dk-body ::v-deep .wh_content_item .wh_chose_day {
  background-color: rgba(70, 200, 164, 1);
}

.dk-body ::v-deep .wh_content_item .wh_item_date,
.dk-body ::v-deep .wh_content_item .wh_top_tag {
  border-radius: 40%;
  height: 28px;
  line-height: 28px;
}

.dk-body ::v-deep .wh_content_item .wh_item_date:hover {
  background-color: rgba(70, 200, 164, 1);
}

.dk-body ::v-deep .wh_content {
  padding: 5px 1%;
}

.comment-user {
  display: flex;
  align-items: center;
  color: #fff;
  font-size: 12px;
  flex: 1;
}

.init-dialog ::v-deep .el-dialog {
  padding: 0 80px;
  border-radius: 8px;
}

.init-dialog ::v-deep .el-dialog__header {
  padding: 20px 0 10px;
}

.init-dialog ::v-deep .el-dialog__body {
  padding: 40px 0;
}

.init-dialog ::v-deep .el-dialog__footer {
  padding-bottom: 45px;
}

.dk-body .vfc-popover-container ::v-deep .vfc-main-container {
  box-shadow: unset;
}

.vfc-popover-container ::v-deep .vfc-top-date {
  background: rgba(250, 100, 0, 0.1);
  margin: 0;
  padding: 11px 25px;
  font-size: 16px;
  margin-bottom: 15px;
}

.vfc-popover-container ::v-deep .vfc-top-date span {
  color: #fa6400;
}

.vfc-popover-container ::v-deep .vfc-cursor-pointer {
  margin: 8px 20px;
}

.vfc-popover-container ::v-deep.vfc-content {
  margin: 0;
}

.vfc-popover-container ::v-deep .vfc-arrow-left {
  border-color: #fa6400;
}

.vfc-popover-container ::v-deep .vfc-arrow-right {
  border-color: #fa6400;
}

.vfc-popover-container ::v-deep .vfc-week .vfc-day span.vfc-span-day {
  border-radius: 14px;
  width: 40px;
}

.vfc-popover-container ::v-deep .vfc-week .vfc-day {
  margin-top: 10px;
}

.vfc-popover-container
  ::v-deep
  .vfc-week
  .vfc-day
  span.vfc-span-day.vfc-marked {
  background-color: rgba(70, 200, 164, 1);
  color: #333333;
}

.vfc-popover-container
  ::v-deep
  .vfc-week
  .vfc-day
  span.vfc-span-day.dk-already {
  background-color: rgba(70, 200, 164, 1);
  color: #333333;
}
.vfc-popover-container
  ::v-deep
  .vfc-week
  .vfc-day
  span.vfc-span-day.dk-already-level1 {
  background-color: rgba(70, 200, 164, 0.2);
  color: #333333;
  cursor: pointer;
}
.vfc-popover-container
  ::v-deep
  .vfc-week
  .vfc-day
  span.vfc-span-day.dk-already-level2 {
  background-color: rgba(70, 200, 164, 0.5);
  color: #333333;
  cursor: pointer;
}
.vfc-popover-container
  ::v-deep
  .vfc-week
  .vfc-day
  span.vfc-span-day.dk-already-level3 {
  background-color: rgba(70, 200, 164, 0.7);
  color: #333333;
  cursor: pointer;
}
.vfc-popover-container
  ::v-deep
  .vfc-week
  .vfc-day
  span.vfc-span-day.dk-already-level4 {
  background-color: #46c8a4;
  color: #333333;
  cursor: pointer;
}

.vfc-popover-container ::v-deep .vfc-week .vfc-day span.vfc-span-day.dk-not {
  background-color: rgba(70, 200, 164, 0.2);
  color: #333333;
}

.vfc-popover-container
  ::v-deep
  .vfc-week
  .vfc-day
  span.vfc-span-day.vfc-hover:hover {
  background-color: rgba(70, 200, 164, 1);
}

.vfc-popover-container ::v-deep .vfc-week .vfc-day span.vfc-span-day.vfc-today {
  background: rgba(250, 100, 0, 0.1);
  color: #fa6400;
}

.task-end {
  color: #34495e;
  display: flex;
  align-items: center;
  text-align: right;
  font-size: 14px;
}

.task-end .time {
  color: #fa6400;
  font-size: 18px;
}

.team-cell:hover {
  cursor: pointer;
}

.copy-btn {
  border-radius: 0;
  width: 90px;
}
::v-deep .el-input-number--mini {
  width: 96px;
  font-size: 14px;
}
::v-deep .el-input-number--mini .el-input-number__decrease,
::v-deep .el-input-number--mini .el-input-number__increase {
  font-size: 14px;
}
::v-deep .el-input--mini .el-input__inner {
  font-size: 14px;
}
</style>
