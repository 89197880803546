<template>
<!-- 目标面板 -->
  <div class="comment-rt">
    <div class="comment-card">
      <div class="comment-card-head">
        <div class="comment-card-title aside-title-border">
          刷题量<!-- <span class="text-warn">（100）</span> -->
        </div>
        <el-button
          type="text"
          class="modify-btn"
          size="mini"
          @click="openTargetDialog"
          >修改</el-button
        >
      </div>
      <div class="comment-card-body">
        <div class="brush-data">
          <div class="brush-data-item">
            <div :style="{ color: color1 }" class="brush-data-text">今日</div>
            <el-progress
              type="circle"
              :percentage="
                progressDetail.today &&
                parseInt(
                  progressDetail.today.percent > 100
                    ? 100
                    : progressDetail.today.percent
                )
              "
              :color="color1"
              :width="72"
              :stroke-width="3"
            ></el-progress>
          </div>
          <div class="brush-data-item">
            <div :style="{ color: color2 }" class="brush-data-text">一 周</div>
            <el-progress
              type="circle"
              :percentage="
                progressDetail.thisWeek &&
                parseInt(
                  progressDetail.thisWeek.percent > 100
                    ? 100
                    : progressDetail.thisWeek.percent
                )
              "
              :color="color2"
              :width="72"
              :stroke-width="3"
            ></el-progress>
          </div>
          <div class="brush-data-item">
            <div :style="{ color: color3 }" class="brush-data-text">月 度</div>
            <el-progress
              type="circle"
              :percentage="
                progressDetail.thisMonth &&
                parseInt(
                  progressDetail.thisMonth.percent > 100
                    ? 100
                    : progressDetail.thisMonth.percent
                )
              "
              :color="color3"
              :width="72"
              :stroke-width="3"
            ></el-progress>
          </div>
        </div>
        <div class="brush-target">
          *目标：每日{{ perDay }}题 每周{{ perWeek }}题 每月{{ perMonth }}题
        </div>
      </div>
    </div>
    <div class="comment-card comment-card-plan">
      <!-- <div class="comment-card-select">
        <el-select v-model="plan">
          <el-option label="速成班规划" value="1"></el-option>
          <el-option label="速成班规划" value="2"></el-option>
        </el-select>
      </div> -->
      <div class="brush-status">
        <div class="brush-status-item">
          <div class="brush-status-text">
            <span
              class="iconfont icon-zhaozhuanye"
              :style="{ color: color1 }"
            ></span>
            刷题目标
          </div>
          <div class="brush-status-value" :style="{ color: color1 }">
            {{ progressDetail.target }}题
          </div>
        </div>
        <div class="brush-status-item">
          <div class="brush-status-text">
            <span class="iconfont icon-dui2" :style="{ color: color2 }"></span>
            已完成
          </div>
          <div class="brush-status-value" :style="{ color: color2 }">
            {{ progressDetail.completed }}题
          </div>
        </div>
        <div class="brush-status-item">
          <div class="brush-status-text">
            <span
              class="iconfont icon-qbzuo04"
              :style="{ color: color3 }"
            ></span>
            剩余天数
          </div>
          <div class="brush-status-value" :style="{ color: color3 }">
            {{ progressDetail.remainingDays }}天
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { getTargetDetail } from "../../../service/punchClock.js";
export default {
  name: "targetPanel",
  props: {
    data: {
      type: Object,
      required: true,
      default: () => {
        return {
          targetNum: 0,
          targetDay: 0,
        };
      },
    },
  },
  data() {
    return {
      color1: "#46C8A4",
      color2: "#FA6400",
      color3: "#0075F6",
      progressDetail: {},
    };
  },
  methods: {
    getPanelDetail() {
      getTargetDetail().then((res) => {
        if (res.success) {
          this.progressDetail = res.result;
        }
      });
    },
    openTargetDialog() {
      this.$emit("editTargetDialog");
    },
  },
  computed: {
    perDay() {
      return Math.floor(this.data.targetNum / this.data.targetDay);
    },
    perWeek() {
      return Math.floor(this.data.targetNum / (this.data.targetDay / 7));
    },
    perMonth() {
      return Math.floor(this.data.targetNum / (this.data.targetDay / 30));
    },
  },
  mounted() {
    this.getPanelDetail();
    this.$bus.$on("updateStatistics", (e) => {
      console.log(e);
      if (e == "update") {
        console.log("update!");
        this.getPanelDetail();
      }
    });
  },
};
</script>
<style scoped>
.comment-rt {
  float: right;
  width: 422px;
}

.comment-card {
  padding: 20px;
  background-color: #fff;
  margin-bottom: 10px;
  border-radius: 4px;
}

.comment-card-head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 10px;
  font-size: 14px;
  margin-bottom: 10px;
  color: #34495e;
  position: relative;
}

.aside-title-border {
  color: #34495e;
  padding-left: 10px;
  position: relative;
}

.aside-title-border::before {
  position: absolute;
  content: "";
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 3px;
  height: 16px;
  background: #fa6400;
  border-radius: 2px;
}

.brush-data {
  display: flex;
  align-items: center;
  justify-content: center;
}

.brush-data-item {
  margin: 0 17px;
}

.brush-data-text {
  text-align: center;
  font-size: 14px;
  padding: 6px;
}

.brush-target {
  font-size: 12px;
  color: #8894ab;
  text-align: center;
  padding: 10px;
}
.brush-status-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  color: rgba(52, 73, 94, 1);
  padding: 6px 0;
}
.brush-status-text .iconfont {
  font-size: 14px;
  margin-right: 14px;
}

.brush-status {
  padding: 5px 10px 7px;
}
.modify-btn {
  text-decoration: underline;
  color: #8894ab;
}

.progress-item ::v-deep .el-progress-bar__inner::after {
  width: 10px;
  height: 10px;
  background-color: #fff;
  border-radius: 50%;
  position: absolute;
  right: 2px;
  content: "";
  z-index: 11;
}

.progress-item ::v-deep .el-progress-bar__inner::before {
  width: 14px;
  height: 14px;
  background-color: rgb(0, 117, 246);
  border-radius: 50%;
  position: absolute;
  right: 0;
  top: -2px;
  content: "";
  z-index: 11;
  box-shadow: 0px 1px 2px 0px #00418a;
}

.progress-item ::v-deep .el-progress-bar__outer {
  overflow: unset;
}

.text-warn {
  color: #fa6400;
}
.el-progress {
  flex: 1;
}
</style>