<template>
<!-- 打卡相关 -->
  <el-tabs type="border-card" v-model="activeTab" :class="[activeTab]">
    <el-tab-pane label="个 人" name="personal">
      <el-row class="form-demo">
        <el-col :span="7" :offset="13">
          <el-input
            placeholder="搜索题号"
            prefix-icon="el-icon-search"
            v-model="searchKey"
            size="small"
            @input="searchMyClockRecord"
          >
          </el-input>
        </el-col>
        <el-col :span="4">
          <el-button
            type="info"
            size="small"
            icon="el-icon-circle-plus-outline"
            @click="importQuestionVisible = true"
            plain
            >批量打卡
          </el-button>
        </el-col>
      </el-row>

      <div class="pel-table">
        <div class="pel-table-head">
          标注：
          <div class="pel-mark">
            <div class="is-dot" :style="{ backgroundColor: red }"></div>
            很难提示
          </div>
          <div class="pel-mark">
            <div class="is-dot" :style="{ backgroundColor: orange }"></div>
            特别注意
          </div>
          <div class="pel-mark">
            <div class="is-dot" :style="{ backgroundColor: green }"></div>
            轻松跳过
          </div>
        </div>
        <el-table
          ref="multipleTable"
          :data="myClockData"
          tooltip-effect="dark"
          style="width: 100%"
        >
          <el-table-column
            prop="questionName"
            label="题目"
            align="left"
            width="430px"
          >
          </el-table-column>
          <el-table-column prop="mark" label="标注" align="center" width="70px">
            <template slot-scope="scope">
              <el-dropdown @command="handleCommand">
                <span class="el-dropdown-link">
                  <div
                    class="is-dot"
                    :style="{
                      backgroundColor:
                        scope.row.difficulty == 1
                          ? green
                          : scope.row.difficulty == 2
                          ? orange
                          : red,
                    }"
                  ></div>
                  <i class="el-icon-arrow-down el-icon--right"></i>
                </span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item
                    :command="beforeHandleCommand(scope.row, 'hard')"
                    >很难提示</el-dropdown-item
                  >
                  <el-dropdown-item
                    :command="beforeHandleCommand(scope.row, 'normal')"
                    >特别注意</el-dropdown-item
                  >
                  <el-dropdown-item
                    :command="beforeHandleCommand(scope.row, 'easy')"
                    >轻松跳过</el-dropdown-item
                  >
                </el-dropdown-menu>
              </el-dropdown>
            </template>
          </el-table-column>
          <el-table-column
            prop="note"
            label="笔记"
            align="center"
            width="106px"
          >
            <template slot-scope="scope">
              <div>
                <i
                  class="el-icon-document"
                  :style="{
                    color:
                      scope.row.node && scope.row.node != ''
                        ? 'rgba(8, 169, 0, 1)'
                        : '#8894ab',
                  }"
                  @click="openNotes(scope.row)"
                ></i>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            prop="algorithmId"
            label="视频"
            align="center"
            width="106px"
          >
            <template slot-scope="scope">
              <div class="video" v-if="scope.row.algorithmId">
                <div class="video-explanation" @click="jumpUrl(scope)">
                  视频讲解
                </div>
              </div>
              <div class="video video-disabled" v-else>
                <div class="video-explanation">视频讲解</div>
                <i class="el-icon-lock"></i>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            prop="createTime"
            label="时间"
            align="center"
            width="116px"
          ></el-table-column>
        </el-table>
      </div>
      <div class="pagination-block">
        <el-pagination
          background
          prev-text="上一页"
          next-text="下一页"
          layout="prev, pager, next "
          :total="myClockRecordTotal"
          :current-page="myClockRecordCurrent"
          @current-change="getMyClockRecord"
        >
        </el-pagination>
      </div>
    </el-tab-pane>
    <el-tab-pane label="官 方" name="offcial">
      <el-row class="form-demo">
        <el-col :span="7">
          <el-select v-model="officialClockType" size="small">
            <el-option
              v-for="(officialClass, index) in officialClasses"
              :key="index"
              :label="officialClass.name"
              :value="officialClass.id"
            ></el-option>
          </el-select>
        </el-col>
      </el-row>

      <div class="pel-table">
        <div class="pel-table-head">
          标注：
          <div class="pel-mark">
            <div class="is-dot" :style="{ backgroundColor: red }"></div>
            很难提示
          </div>
          <div class="pel-mark">
            <div class="is-dot" :style="{ backgroundColor: orange }"></div>
            特别注意
          </div>
          <div class="pel-mark">
            <div class="is-dot" :style="{ backgroundColor: green }"></div>
            轻松跳过
          </div>
        </div>
        <div>
          <el-tabs
            type="border-card"
            class="order-tabs"
            v-model="activeOfficialTab"
          >
            <el-tab-pane
              v-for="(officialTab, index) in officialTabs"
              :key="index"
              :label="officialTab.name"
              :name="`${officialTab.id}`"
            >
            </el-tab-pane>
          </el-tabs>
          <el-table
            ref="multipleTable"
            :data="officialData"
            tooltip-effect="dark"
            style="width: 100%"
            @selection-change="handleSelectionChange"
          >
            <el-table-column label="是否打卡" width="100" align="center">
              <template slot-scope="scope">
                <el-checkbox
                  v-model="scope.row.isClock"
                  @change="changeClockEvent($event, scope)"
                ></el-checkbox>
              </template>
            </el-table-column>
            <el-table-column
              prop="questionName"
              label="题目"
              align="left"
              width="400px"
            >
            </el-table-column>
            <el-table-column
              prop="mark"
              label="标注"
              align="center"
              width="118px"
            >
              <template slot-scope="scope">
                <span class="el-dropdown-link">
                  <div
                    class="is-dot"
                    :style="{
                      backgroundColor:
                        scope.row.difficulty == 1
                          ? green
                          : scope.row.difficulty == 2
                          ? orange
                          : red,
                    }"
                  ></div>
                </span>
              </template>
            </el-table-column>
            <el-table-column
              prop="note"
              label="笔记"
              align="center"
              width="106px"
            >
              <template slot-scope="scope">
                <div>
                  <i
                    class="el-icon-document"
                    :style="{
                      color:
                        scope.row.node && scope.row.node != ''
                          ? 'rgba(8, 169, 0, 1)'
                          : '#8894ab',
                    }"
                    @click="openNotes(scope.row)"
                  ></i>
                </div>
              </template>
            </el-table-column>
            <el-table-column
              prop="algorithmId"
              label="视频"
              align="center"
              width="106px"
            >
              <template slot-scope="scope">
                <div class="video" v-if="scope.row.algorithmId">
                  <div class="video-explanation" @click="jumpUrl(scope)">
                    视频讲解
                  </div>
                </div>
                <div class="video video-disabled" v-else>
                  <div class="video-explanation">视频讲解</div>
                  <i class="el-icon-lock"></i>
                </div>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
      <div class="pagination-block">
        <el-pagination
          background
          prev-text="上一页"
          next-text="下一页"
          layout="prev, pager, next"
          :total="officialClockTotal"
          :current-page="officialClockCurrent"
          @current-change="getOfficialClassData"
        >
        </el-pagination>
      </div>
    </el-tab-pane>

    <el-tab-pane label="动 态" name="activity">
      <publishComment type="default"></publishComment>
    </el-tab-pane>

    <testNotes
      :visible="notesVisible"
      @changeNotesVisible="(val) => (notesVisible = val)"
      :notes="notes"
      @updateClocks="updateClocks"
    ></testNotes>
    <importQuestions
      :visible="importQuestionVisible"
      @changeImportQuestionVisible="(val) => (importQuestionVisible = val)"
      @updateMyClocks="getMyClockRecord"
    ></importQuestions>
  </el-tabs>
</template>
<script>
import publishComment from "../publishComment";
import importQuestions from "./importQuestions";
import testNotes from "./testNotes";
import _ from "lodash";
import {
  getMyClockRecords,
  changeDifficulty,
  getOfficialClasses,
  getOfficialClassTabs,
  getOfficialClassDetail,
  cancelClock,
  saveClocks,
} from "../../../service/punchClock.js";
export default {
  name: "clockTabPanel",
  components: { publishComment, importQuestions, testNotes },
  data() {
    return {
      activeTab: "personal",
      red: "rgba(255, 0, 0, 1)",
      orange: "rgba(250, 182, 0, 1)",
      green: "rgba(8, 169, 0, 1)",
      searchKey: "",
      importQuestionVisible: false,
      myClockRecordCurrent: 1,
      myClockRecordTotal: 0,
      myClockData: [],

      notesVisible: false,
      officialClockType: "",

      officialClasses: [],
      officialData: [],
      officialClockCurrent: 1,
      officialClockTotal: 1,
      notes: {},
      officialTabs: [],
      activeOfficialTab: "",
      multipleSelection: [],
    };
  },
  mounted() {
    this.getMyClockRecord();
    this.getOfficialClasses();
  },
  methods: {
    jumpUrl(scope) {
      // window.location.href = scope.row.videoUrl;
      console.log(scope.row);
      this.$router.push(`/watchVideo?id=${scope.row.algorithmId}`);
    },
    changeClockEvent(e, scope) {
      console.log(e, scope.row);
      if (e) {
        const data = {
          courseSectionId: [scope.row.questionNum],
        };
        saveClocks(data).then((res) => {
          if (res.success) {
            this.$message.success("打卡成功!");
          } else {
            this.$message.error(res.message);
          }
        });
      } else {
        cancelClock(scope.row.id).then((res) => {
          if (res.success) {
            this.$message.success("取消打卡成功！");
          }
        });
      }
    },
    getMyClockRecord(page) {
      getMyClockRecords(page ? page : 1, this.searchKey).then((res) => {
        if (res.success) {
          console.log(res);
          this.myClockRecordCurrent = page ? page : 1;
          this.myClockData = res.result.records;
          this.myClockRecordTotal = res.result.total;
        }
      });
    },
    getOfficialClassData(page) {
      getOfficialClassDetail(
        this.activeOfficialTab,
        page ? page : this.officialClockCurrent
      ).then((res) => {
        if (res.success) {
          console.log(123);
          this.officialData = res.result.records;
          this.officialClockTotal = res.result.total;
        }
      });
    },
    searchMyClockRecord: _.debounce(function () {
      this.getMyClockRecord();
    }, 1000),
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },

    beforeHandleCommand(row, command) {
      return {
        row: row,
        command: command,
      };
    },
    handleCommand(command) {
      switch (command.command) {
        case "hard":
          this.changeDifficulty(command.row.id, 3);
          break;
        case "normal":
          this.changeDifficulty(command.row.id, 2);
          break;
        case "easy":
          this.changeDifficulty(command.row.id, 1);
          break;
      }
    },
    changeDifficulty(id, difficulty) {
      changeDifficulty(id, difficulty).then((res) => {
        if (res.success) {
          if (this.activeTab == "personal") {
            this.getMyClockRecord(this.myClockRecordCurrent);
          } else if (this.activeTab == "offcial") {
            this.getOfficialClassData();
          }
        }
      });
    },
    openNotes(item) {
      this.notes = item;
      this.notesVisible = true;
    },
    updateClocks() {
      if (this.activeTab == "personal") {
        this.getMyClockRecord(this.myClockRecordCurrent);
      }
    },
    getOfficialClasses() {
      getOfficialClasses().then((res) => {
        if (res.success) {
          this.officialClasses = res.result;
          this.officialClockType = res.result[0].id;
        }
      });
    },
  },
  watch: {
    officialClockType(val) {
      if (val != "") {
        getOfficialClassTabs(val).then((res) => {
          if (res.success) {
            this.officialTabs = res.result;
            this.activeOfficialTab = `${res.result[0].id}`;
          }
        });
      }
    },
    activeOfficialTab(val) {
      if (val != "") {
        getOfficialClassDetail(val, 1).then((res) => {
          if (res.success) {
            this.officialData = res.result.records;
            this.officialClockTotal = res.result.total;
          }
        });
      }
    },
  },
};
</script>
<style scoped>
.is-dot {
  width: 10px;
  height: 10px;
  border-radius: 5px;
  display: inline-block;
  margin-right: 2px;
}

.pel-table-head {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 20px 0;
}

.pel-mark {
  display: flex;
  align-items: center;
  margin-left: 10px;
}

.video-explanation {
  text-decoration: underline;
  cursor: pointer;
}

.video {
  position: relative;
}

.video i {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 11;
  color: #000;
}

.video-disabled .video-explanation {
  color: rgba(136, 148, 171, 1);
  opacity: 0.5;
}

.el-pagination ::v-deep .el-pager li {
  height: 21px;
  line-height: 21px;
  min-width: 22px;
  font-size: 12px;
  border-radius: 50%;
  color: rgba(0, 0, 0, 0.5);
}

.el-pagination.is-background ::v-deep .el-pager li:not(.disabled).active {
  background-color: rgba(250, 100, 0, 1);
}

.el-pagination ::v-deep button,
.el-pagination ::v-deep span {
  font-size: 12px;
  height: 21px;
  line-height: 21px;
  color: rgba(0, 0, 0, 0.5);
  text-align: center;
}

.el-pagination {
  display: inline-block;
  position: relative;
  padding: 0 50px;
}

.el-pagination .last {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

.el-pagination .first {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}

.pagination-block {
  text-align: center;
  padding: 45px 0 25px;
  background-color: #fff;
}
.comment-card-plan {
  padding: 15px 30px;
}

.comment-card-select .el-select ::v-deep .el-input__inner {
  height: 26px;
  line-height: 26px;
  font-size: 12px;
  background: rgba(250, 100, 0, 0.1);
  border-radius: 4px;
  border: 1px solid transparent;
  width: 150px;
  color: rgba(250, 100, 0, 1);
}

.comment-card-select .el-select ::v-deep .el-input__icon {
  font-size: 12px;
  color: rgba(250, 100, 0, 1);
  line-height: 26px;
}

.el-tabs {
  box-shadow: 0px 0px 5px 0px rgba(136, 148, 171, 0.2);
  border-radius: 4px;
  border: 0;
}

.el-tabs ::v-deep .el-tabs__header {
  background-color: #fff;
  border-bottom: 0;
  box-shadow: 0px 0px 5px 0px rgba(136, 148, 171, 0.2);
}

.el-tabs ::v-deep .el-tabs__header .el-tabs__item {
  border: 0;
  margin: 0;
  flex: 1;
  text-align: center;
  height: 48px;
  line-height: 48px;
  box-shadow: 0px 0px 5px 0px rgba(136, 148, 171, 0.2);
}

.el-tabs ::v-deep .el-tabs__header .el-tabs__item:hover {
  color: rgba(250, 100, 0, 1);
}

.el-tabs ::v-deep .el-tabs__header .el-tabs__item.is-active {
  color: rgba(250, 100, 0, 1);
  background-color: rgba(250, 100, 0, 0.1);
}

.el-tabs ::v-deep .el-tabs__header .el-tabs__nav {
  display: flex;
  float: unset;
}

.form-demo .el-select ::v-deep .el-input__inner {
  background: rgba(250, 100, 0, 0.1);
  border: 1px solid transparent;
  color: rgba(250, 100, 0, 1);
}

.form-demo .el-select ::v-deep .el-input .el-select__caret {
  color: rgba(250, 100, 0, 1);
}

.form-demo .el-input ::v-deep .el-input__inner {
  background: rgba(235, 236, 241, 0.91);
  border: 1px solid transparent;
  text-align: center;
  width: 230px;
}

.form-demo .el-input ::v-deep .el-input__prefix {
  left: 30%;
}

.form-demo .el-button {
  width: 130px;
  background: rgba(235, 236, 241, 0.91);
  float: right;
  border: 1px solid transparent;
}
.editor .quill-editor ::v-deep .ql-container {
  min-height: 200px;
}

.order-tabs ::v-deep .el-tabs__header .el-tabs__nav {
  float: left;
  border-radius: 4px 4px 0 0;
}

.order-tabs ::v-deep .el-tabs__header {
  box-shadow: unset;
}

.order-tabs {
  box-shadow: unset;
}

.order-tabs ::v-deep .el-tabs__header .el-tabs__item {
  height: 28px;
  line-height: 28px;
  background: rgba(235, 236, 241, 0.91);
  color: #34495e;
  font-size: 14px;
}

.order-tabs ::v-deep .el-tabs__header .el-tabs__item.is-active {
  background: #e7f1fd;
  color: #0075f6;
}

.order-tabs ::v-deep .el-tabs__header .el-tabs__item:hover {
  color: #0075f6;
}

.order-tabs ::v-deep .el-tabs__content {
  padding: 0;
}

.el-table ::v-deep thead th {
  background: #e7f1fd;
  color: #0075f6;
}
::v-deep .el-tabs__nav-next,
::v-deep .el-tabs__nav-prev {
  line-height: 28px;
}
::v-deep .el-tabs {
  border-radius: 4px;
}

::v-deep .el-tabs__content {
  padding: 30px;
}
::v-deep .el-button--info.is-plain:focus,
.el-button--info.is-plain:hover {
  color: #909399;
}
::v-deep .el-table td,
::v-deep .el-table th {
  padding: 12px 0 12px 10px;
}
::v-deep .el-input--small .el-input__inner {
  line-height: 36px;
  height: 36px;
}
.el-tabs.activity ::v-deep .el-tabs__content {
  padding: 0 !important;
}
</style>