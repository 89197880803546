<template>
  <!-- 历史测试 -->
  <el-dialog :visible.sync="dialogVisible" width="800px" class="history-dialog"  :close-on-click-modal="false">
    <div class="history-dialog-head">
      <div class="history-dialog-flex">
        <div slot="title" class="dialog-head">刷题历史记录</div>
        <el-input
          placeholder="搜索题号"
          prefix-icon="el-icon-search"
          size="small"
          v-model="searchKey"
        >
        </el-input>
      </div>
      <div class="pel-table-head">
        标注：
        <div class="pel-mark">
          <div class="is-dot" :style="{ backgroundColor: red }"></div>
          很难提示
        </div>
        <div class="pel-mark">
          <div class="is-dot" :style="{ backgroundColor: orange }"></div>
          特别注意
        </div>
        <div class="pel-mark">
          <div class="is-dot" :style="{ backgroundColor: green }"></div>
          轻松跳过
        </div>
      </div>
    </div>
    <el-table
      :data="historyData"
      style="width: 100%"
      :default-sort="{ prop: 'date', order: 'descending' }"
      class="his-table"
      size="mini"
    >
      <el-table-column prop="questionName" label="LeetCode题目">
      </el-table-column>
      <el-table-column
        prop="difficulty"
        label="题目难度"
        :formatter="formatter"
        align="center"
        width="180px"
      >
        <template slot-scope="scope">
          <el-dropdown @command="handleCommand">
            <span class="el-dropdown-link">
              <div
                class="is-dot"
                :style="{
                  backgroundColor:
                    scope.row.difficulty == 1
                      ? green
                      : scope.row.difficulty == 2
                      ? orange
                      : red,
                }"
              ></div>
              <i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item
                :command="beforeHandleCommand(scope.row, 'hard')"
                >很难提示</el-dropdown-item
              >
              <el-dropdown-item
                :command="beforeHandleCommand(scope.row, 'normal')"
                >特别注意</el-dropdown-item
              >
              <el-dropdown-item
                :command="beforeHandleCommand(scope.row, 'easy')"
                >轻松跳过</el-dropdown-item
              >
            </el-dropdown-menu>
          </el-dropdown>
        </template>
      </el-table-column>
    </el-table>
    <div class="history-brush" v-show="searchKey == ''">
      已刷题数：{{ total }} 题
    </div>
    <div class="pagination-block" v-show="searchKey == '' && total > 15">
      <el-pagination
        background
        prev-text="上一页"
        next-text="下一页"
        layout="prev, pager, next "
        :total="total"
        :current-page="currentPage"
        @current-change="getHistoryTestData"
        :page-size="15"
      >
      </el-pagination>
    </div>
    <div slot="footer" class="dialog-foot"></div>
  </el-dialog>
</template>

<script>
import {
  getClockRecordsByDate,
  changeDifficulty,
} from "../../../service/punchClock.js";
export default {
  name: "historyTest",
  props: {
    visible: {
      type: Boolean,
      default: false,
      required: true,
    },
    choosedDay: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      historyData: [],
      red: "rgba(255, 0, 0, 1)",
      orange: "rgba(250, 182, 0, 1)",
      green: "rgba(8, 169, 0, 1)",
      searchKey: "",
      currentPage: 1,
      total: 0,
    };
  },
  methods: {
    formatter(row, column) {
      return row.address;
    },
    getHistoryTestData(currentPage) {
      this.currentPage = currentPage;
      getClockRecordsByDate(this.choosedDay, currentPage).then((res) => {
        if (res.success) {
          console.log(res);
          this.historyData = res.result.records;
          this.total = res.result.total;
        }
      });
    },
    beforeHandleCommand(row, command) {
      return {
        row: row,
        command: command,
      };
    },
    handleCommand(command) {
      switch (command.command) {
        case "hard":
          this.changeDifficulty(command.row.id, 3);
          break;
        case "normal":
          this.changeDifficulty(command.row.id, 2);
          break;
        case "easy":
          this.changeDifficulty(command.row.id, 1);
          break;
      }
    },
    changeDifficulty(id, difficulty) {
      changeDifficulty(id, difficulty).then((res) => {
        if (res.success) {
          this.getHistoryTestData(this.currentPage);
        }
      });
    },
  },
  mounted() {},
  computed: {
    dialogVisible: {
      get() {
        return this.visible;
      },
      set(val) {
        this.$emit("changeHistoryVisible", val);
      },
    },
  },
  watch: {
    dialogVisible(val) {
      if (val) {
        this.getHistoryTestData(this.currentPage);
      } else {
        this.historyData = [];
        this.currentPage = 1;
        this.searchKey = "";
      }
    },
    searchKey() {
      this.currentPage = 1;
      getClockRecordsByDate(this.choosedDay, 1, this.searchKey).then((res) => {
        if (res.success) {
          this.historyData = res.result.records;
        }
      });
    },
  },
};
</script>
<style scoped>
.history-dialog-flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.history-dialog-flex .el-input {
  width: 200px;
}

.history-dialog-flex .el-input ::v-deep .el-input__inner {
  border-radius: 16px;
}

.history-dialog-flex .dialog-head {
  border-bottom: 0;
  padding: 0 12px;
}

.history-dialog ::v-deep .el-dialog {
  padding: 0 60px;
}

.history-dialog-head {
  padding: 0 0px;
  border-bottom: 1px solid #ddd;
}

.history-dialog-head ::v-deep .el-input__inner {
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.25) inset;
}

.history-dialog ::v-deep .el-dialog__body {
  padding: 0;
}

::v-deep .el-dialog__footer {
  padding: 0 20px 20px;
}

.history-brush {
  text-align: center;
  color: #fa6400;
  font-size: 16px;
  line-height: 24px;
  padding: 20px 0;
}

.pel-table-head {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 20px 0;
}

.pel-mark {
  display: flex;
  align-items: center;
  margin-left: 10px;
}
.is-dot {
  width: 10px;
  height: 10px;
  border-radius: 5px;
  display: inline-block;
  margin-right: 2px;
}

.dialog-head {
  padding: 15px 12px;
  border-bottom: 1px solid rgba(235, 236, 241, 0.91);
  font-size: 18px;
  color: #34495e;
  position: relative;
}

.dialog-head::before {
  position: absolute;
  content: "";
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 3px;
  height: 18px;
  background: #fa6400;
  border-radius: 2px;
}

.el-table--mini ::v-deep td,
.el-table--mini ::v-deep th {
  padding: 0;
  border: 0;
  color: rgba(0, 0, 0, 0.5);
}

.el-table--mini ::v-deep th {
  background: rgba(0, 117, 246, 0.1);
  color: #34495e;
}

.el-table--mini ::v-deep .el-table__row td:first-child .cell div {
  width: 16px;
  height: 16px;
  background: #b6daff;
  line-height: 16px;
  text-align: center;
  border-radius: 2px;
  color: #fff;
  margin: 0 auto;
}

.el-table--mini ::v-deep .el-table__row:first-child td:first-child .cell div {
  background: #ff5a43;
}

.el-table--mini ::v-deep .el-table__row:nth-child(2) td:first-child .cell div {
  background: #44d7b6;
}

.el-table--mini ::v-deep .el-table__row:nth-child(3) td:first-child .cell div {
  background: #f8c51c;
}

.el-table--mini ::v-deep .el-table__body-wrapper {
  padding: 10px 0;
}
.dialog-foot {
  text-align: center;
}

.dialog-foot .btn {
  min-width: 88px;
}

.warn {
  background-color: #fa6400 !important;
}

.grey {
  background-color: #8894ab !important;
}
.el-pagination ::v-deep .el-pager li {
  height: 21px;
  line-height: 21px;
  min-width: 22px;
  font-size: 12px;
  border-radius: 50%;
  color: rgba(0, 0, 0, 0.5);
}

.el-pagination.is-background ::v-deep .el-pager li:not(.disabled).active {
  background-color: rgba(250, 100, 0, 1);
}

.el-pagination ::v-deep button,
.el-pagination ::v-deep span {
  font-size: 12px;
  height: 21px;
  line-height: 21px;
  color: rgba(0, 0, 0, 0.5);
  text-align: center;
}

.el-pagination {
  display: inline-block;
  position: relative;
  padding: 0 50px;
}

.el-pagination .last {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

.el-pagination .first {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}

.pagination-block {
  text-align: center;
  padding: 25px 0;
  background-color: #fff;
}

::v-deep .el-dialog__headerbtn .el-dialog__close {
  font-size: 28px;
}
::v-deep .el-dialog__header {
  padding: 40px 20px 10px;
}
::v-deep .cell {
  padding: 6px 10px 6px 20px !important;
}
</style>