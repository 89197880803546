<template>
  <!-- 刷题笔记 -->
  <el-dialog
    :visible.sync="dialogVisible"
    width="650px"
    :show-close="false"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
  >
    <div class="dialog-head" slot="title">刷题笔记</div>
    <div class="editor">
      <el-input
        @focus="handleFocus"
        v-show="!showEditor"
        placeholder="写下你的笔记..."
      ></el-input>
      <div id="myWangEditor" v-show="showEditor">
        <Toolbar
          style="
            border: 1px solid #dcdfe6;
            border-bottom: none;
            border-top-left-radius: 5px;
            border-top-right-radius: 5px;
          "
          :editor="editorId"
          :defaultConfig="toolbarConfig"
          :mode="mode"
        />

        <!-- 编辑器 -->
        <Editor
          style="
            height: 150px;
            border: 1px solid #dcdfe6;
            border-top: none;
            border-bottom-left-radius: 5px;
            border-bottom-right-radius: 5px;
          "
          :editor="editorId"
          :defaultConfig="editorConfig"
          :defaultContent="getDefaultContent"
          :mode="mode"
          @onChange="onChange"
          @onCreated="onCreated"
        />
      </div>
    </div>
    <div slot="footer" class="dialog-foot">
      <el-button
        type="info"
        size="mini"
        class="btn text"
        @click="dialogVisible = false"
        >取 消
      </el-button>
      <el-button type="warning" size="mini" class="btn warn" @click="saveNotes"
        >保 存
      </el-button>
    </div>
  </el-dialog>
</template>
<script>
import "@wangeditor/editor/dist/css/style.css";
import { Editor, Toolbar } from "@wangeditor/editor-for-vue";
import cloneDeep from "lodash.clonedeep";
import { saveNotes } from "../../../service/punchClock.js";
export default {
  name: "testNotes",
  props: {
    visible: {
      type: Boolean,
      default: false,
      required: true,
    },
    notes: {
      type: Object,
      default: () => {
        return {};
      },
      required: true,
    },
  },
  components: { Editor, Toolbar },

  data() {
    return {
      content: "",
      showEditor: false,
      editorId: null,
      toolbarConfig: {
        toolbarKeys: [
          "codeBlock",
          "|",
          "bold",
          "bulletedList",
          "numberedList",
          {
            key: "group-justify",
            title: "对齐",
            iconSvg:
              '<svg viewBox="0 0 1024 1024"><path d="M768 793.6v102.4H51.2v-102.4h716.8z m204.8-230.4v102.4H51.2v-102.4h921.6z m-204.8-230.4v102.4H51.2v-102.4h716.8zM972.8 102.4v102.4H51.2V102.4h921.6z"></path></svg>',
            menuKeys: [
              "justifyLeft",
              "justifyRight",
              "justifyCenter",
              "justifyJustify",
            ],
          },
          {
            key: "group-indent",
            title: "缩进",
            iconSvg:
              '<svg viewBox="0 0 1024 1024"><path d="M0 64h1024v128H0z m384 192h640v128H384z m0 192h640v128H384z m0 192h640v128H384zM0 832h1024v128H0z m0-128V320l256 192z"></path></svg>',
            menuKeys: ["indent", "delIndent"],
          },
          "|",
          "emotion",
          {
            key: "group-image",
            title: "图片",
            iconSvg:
              '<svg viewBox="0 0 1024 1024"><path d="M959.877 128l0.123 0.123v767.775l-0.123 0.122H64.102l-0.122-0.122V128.123l0.122-0.123h895.775zM960 64H64C28.795 64 0 92.795 0 128v768c0 35.205 28.795 64 64 64h896c35.205 0 64-28.795 64-64V128c0-35.205-28.795-64-64-64zM832 288.01c0 53.023-42.988 96.01-96.01 96.01s-96.01-42.987-96.01-96.01S682.967 192 735.99 192 832 234.988 832 288.01zM896 832H128V704l224.01-384 256 320h64l224.01-192z"></path></svg>',
            menuKeys: ["insertImage", "uploadImage"],
          },
          "|",
          "redo",
          "undo",
          "|",
          "fullScreen",
        ],
      },
      defaultContent: [],
      editorConfig: {
        placeholder: "请输入内容...",
        MENU_CONF: {
          uploadImage: {
            fieldName: "file",
            server:
              process.env.VUE_APP_BASE_URL + "/common/upload?isPublic=true",
            customInsert(res, insertFn) {
              insertFn(res.result.accessibleUrl);
            },
            headers: {
              "X-Access-Token": window.localStorage.getItem("CSON_PAGE_TOKEN"),
            },
            maxFileSize: 5 * 1024 * 1024,
          },
          codeSelectLang: {
            codeLangs: [
              { text: "Javascript", value: "javascript" },
              { text: "Java", value: "java" },
              { text: "Python", value: "python" },
              { text: "PHP", value: "php" },
              { text: "C++", value: "cpp" },
              { text: "C", value: "c" },
            ],
          },
        },
      },
      mode: "default",
      curContent: [],
    };
  },
  methods: {
    onCreated(editor) {
      this.editorId = Object.seal(editor);
    },
    onChange(editor) {
      this.curContent = editor.children;
      this.content = editor.getHtml();
    },
    handleFocus() {
      this.showEditor = true;
      // focus逻辑
    },
    saveNotes() {
      saveNotes({
        id: this.notes.id,
        note: this.content,
      }).then((res) => {
        if (res.success) {
          this.$message.success("笔记保存成功！");
          this.dialogVisible = false;
          this.$emit("updateClocks");
        }
      });
    },
  },
  computed: {
    dialogVisible: {
      get() {
        return this.visible;
      },
      set(val) {
        this.$emit("changeNotesVisible", val);
      },
    },
    getDefaultContent() {
      return cloneDeep(this.defaultContent);
    },
  },
  mounted() {},
  watch: {
    dialogVisible(val) {
      if (val) {
        this.$nextTick(() => {
          // console.log(this.notes);
          // if (this.notes.node && this.notes.node != "") {
          //   this.content = this.notes.node;
          //   this.showEditor = true;
          // }
        });
      } else {
        this.showEditor = false;
      }
    },
  },
};
</script>
<style scoped>
.dialog-head {
  padding: 15px 12px;
  border-bottom: 1px solid rgba(235, 236, 241, 0.91);
  font-size: 18px;
  color: #34495e;
  position: relative;
}

.dialog-head::before {
  position: absolute;
  content: "";
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 3px;
  height: 18px;
  background: #fa6400;
  border-radius: 2px;
}
::v-deep .el-dialog__header {
  padding: 0 20px;
}
.dialog-foot {
  text-align: center;
}

.dialog-foot .btn {
  min-width: 88px;
}
</style>
