<template>
  <!-- 第一次设置目标 -->
  <el-dialog
    :visible.sync="dialogVisible"
    width="800px"
    :show-close="false"
    class="init-dialog"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
  >
    <div slot="title" class="dialog-head">设置目标</div>
    <div class="dialog-bd">
      <div class="progress-item">
        <div class="progress-item-text">
          总刷题数<span>（上限1000题）</span>
        </div>
        <div class="progress-item-val">
          <el-slider v-model="targetNum" :min="1" :max="1000"></el-slider>
          <el-input-number
            style="margin-left: 12px"
            v-model="targetNum"
            :min="1"
            :max="1000"
            size="mini"
          ></el-input-number>
        </div>
      </div>
      <div class="progress-item">
        <div class="progress-item-text">天数<span>（上限365天）</span></div>
        <div class="progress-item-val">
          <el-slider v-model="targetDay" :min="1" :max="365"></el-slider>
          <el-input-number
            style="margin-left: 12px"
            v-model="targetDay"
            :min="1"
            :max="365"
            size="mini"
          ></el-input-number>
        </div>
      </div>
      <div class="set-target">
        <div class="set-target-item">
          每日<el-input v-model="perDay"></el-input>题
        </div>
        <div class="set-target-item">
          每周<el-input v-model="perWeek"></el-input>题
        </div>
        <div class="set-target-item">
          每月<el-input v-model="perMonth"></el-input>题
        </div>
      </div>
    </div>
    <div slot="footer" class="dialog-foot">
      <el-button
        type="warning"
        size="small"
        class="btn warn"
        @click="saveTarget"
        >保 存</el-button
      >
      <el-button type="info" size="small" class="btn text" @click="cancel"
        >取 消</el-button
      >
    </div>
  </el-dialog>
</template>
<script>
import {
  setPrivateInitTarget,
  setPrivateTarget,
} from "../../../service/punchClock.js";
export default {
  name: "targetInit",
  props: {
    visible: {
      type: Boolean,
      default: true,
    },
    type: {
      type: String,
      default: "init",
    },
    data: {
      type: Object,
    },
  },
  data() {
    return {
      targetNum: 120,
      targetDay: 30,
    };
  },
  computed: {
    dialogVisible: {
      get() {
        return this.visible;
      },
      set(val) {
        this.$emit("changeTargetDialogVisible", val);
      },
    },
    perDay() {
      return Math.floor(this.targetNum / this.targetDay);
    },
    perWeek() {
      return Math.floor(this.targetNum / (this.targetDay / 7));
    },
    perMonth() {
      return Math.floor(this.targetNum / (this.targetDay / 30));
    },
  },
  methods: {
    cancel() {
      this.dialogVisible = false;
    },
    saveTarget() {
      if (this.type == "init") {
        setPrivateInitTarget({
          targetNum: this.targetNum,
          targetDay: this.targetDay,
        }).then((res) => {
          if (res.success) {
            this.$message.success("设置个人目标成功！");
            setTimeout(() => {
              window.location.reload();
            }, 1500);
          }
        });
      } else if (this.type == "edit") {
        setPrivateTarget({
          targetNum: this.targetNum,
          targetDay: this.targetDay,
        }).then((res) => {
          if (res.success) {
            this.$message.success("更改个人目标成功！");
            setTimeout(() => {
              window.location.reload();
            }, 1500);
          }
        });
      }
    },
  },
  watch: {
    dialogVisible() {
      if (this.type == "edit") {
        this.targetNum = this.data.targetNum;
        this.targetDay = this.data.targetDay;
      }
    },
  },
};
</script>
<style scoped>
.dialog-foot {
  text-align: center;
}

.dialog-foot .btn {
  min-width: 88px;
}

.warn {
  background-color: #fa6400 !important;
}

.grey {
  background-color: #8894ab !important;
}

.dialog-foot .el-button + .el-button {
  margin-left: 40px;
}

.dialog-body {
  padding: 0 50px;
}

.dialog-head {
  padding: 15px 12px;
  border-bottom: 1px solid rgba(235, 236, 241, 0.91);
  font-size: 18px;
  color: #34495e;
  position: relative;
}

.dialog-head::before {
  position: absolute;
  content: "";
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 3px;
  height: 18px;
  background: #fa6400;
  border-radius: 2px;
}

.progress-item-text {
  font-weight: 500;
  color: #34495e;
  font-size: 16px;
}

.progress-item-text span {
  color: rgba(52, 73, 94, 0.7);
  font-size: 14px;
}

.progress-item-val {
  display: flex;
  align-items: center;
  padding: 10px 0;
}

.el-progress {
  flex: 1;
}

.progress-item-val .el-button-group {
  margin-left: 20px;
  display: flex;
  align-items: center;
}

.progress-item-val .el-button-group .el-button {
  background-color: rgba(237, 238, 242, 1);
}

.progress-item-val .el-button-group .el-input ::v-deep .el-input__inner {
  height: 28px;
  line-height: 28px;
  width: 58px;
  text-align: center;
  border-radius: 0;
}

.set-target {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  color: #34495e;
  line-height: 24px;
  margin-top: 10px;
}

.set-target-item {
  margin: 0 5px;
  display: flex;
  white-space: nowrap;
}

.set-target-item ::v-deep .el-input .el-input__inner {
  background: rgba(250, 100, 0, 0.1);
  font-size: 18px;
  line-height: 24px;
  text-align: center;
  color: #fa6400;
  height: 24px;
  max-width: 50px;
  padding: 0 2px;
  border: 0;
  border-radius: 2px;
}

.text-warn {
  color: #fa6400;
}
.el-slider {
  width: 80%;
}
</style>