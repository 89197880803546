var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"comment-rt"},[_c('div',{staticClass:"comment-card"},[_c('div',{staticClass:"comment-card-head"},[_c('div',{staticClass:"comment-card-title aside-title-border"},[_vm._v(" 刷题量")]),_c('el-button',{staticClass:"modify-btn",attrs:{"type":"text","size":"mini"},on:{"click":_vm.openTargetDialog}},[_vm._v("修改")])],1),_c('div',{staticClass:"comment-card-body"},[_c('div',{staticClass:"brush-data"},[_c('div',{staticClass:"brush-data-item"},[_c('div',{staticClass:"brush-data-text",style:({ color: _vm.color1 })},[_vm._v("今日")]),_c('el-progress',{attrs:{"type":"circle","percentage":_vm.progressDetail.today &&
                parseInt(
                  _vm.progressDetail.today.percent > 100
                    ? 100
                    : _vm.progressDetail.today.percent
                ),"color":_vm.color1,"width":72,"stroke-width":3}})],1),_c('div',{staticClass:"brush-data-item"},[_c('div',{staticClass:"brush-data-text",style:({ color: _vm.color2 })},[_vm._v("一 周")]),_c('el-progress',{attrs:{"type":"circle","percentage":_vm.progressDetail.thisWeek &&
                parseInt(
                  _vm.progressDetail.thisWeek.percent > 100
                    ? 100
                    : _vm.progressDetail.thisWeek.percent
                ),"color":_vm.color2,"width":72,"stroke-width":3}})],1),_c('div',{staticClass:"brush-data-item"},[_c('div',{staticClass:"brush-data-text",style:({ color: _vm.color3 })},[_vm._v("月 度")]),_c('el-progress',{attrs:{"type":"circle","percentage":_vm.progressDetail.thisMonth &&
                parseInt(
                  _vm.progressDetail.thisMonth.percent > 100
                    ? 100
                    : _vm.progressDetail.thisMonth.percent
                ),"color":_vm.color3,"width":72,"stroke-width":3}})],1)]),_c('div',{staticClass:"brush-target"},[_vm._v(" *目标：每日"+_vm._s(_vm.perDay)+"题 每周"+_vm._s(_vm.perWeek)+"题 每月"+_vm._s(_vm.perMonth)+"题 ")])])]),_c('div',{staticClass:"comment-card comment-card-plan"},[_c('div',{staticClass:"brush-status"},[_c('div',{staticClass:"brush-status-item"},[_c('div',{staticClass:"brush-status-text"},[_c('span',{staticClass:"iconfont icon-zhaozhuanye",style:({ color: _vm.color1 })}),_vm._v(" 刷题目标 ")]),_c('div',{staticClass:"brush-status-value",style:({ color: _vm.color1 })},[_vm._v(" "+_vm._s(_vm.progressDetail.target)+"题 ")])]),_c('div',{staticClass:"brush-status-item"},[_c('div',{staticClass:"brush-status-text"},[_c('span',{staticClass:"iconfont icon-dui2",style:({ color: _vm.color2 })}),_vm._v(" 已完成 ")]),_c('div',{staticClass:"brush-status-value",style:({ color: _vm.color2 })},[_vm._v(" "+_vm._s(_vm.progressDetail.completed)+"题 ")])]),_c('div',{staticClass:"brush-status-item"},[_c('div',{staticClass:"brush-status-text"},[_c('span',{staticClass:"iconfont icon-qbzuo04",style:({ color: _vm.color3 })}),_vm._v(" 剩余天数 ")]),_c('div',{staticClass:"brush-status-value",style:({ color: _vm.color3 })},[_vm._v(" "+_vm._s(_vm.progressDetail.remainingDays)+"天 ")])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }