import { render, staticRenderFns } from "./testNotes.vue?vue&type=template&id=19584766&scoped=true"
import script from "./testNotes.vue?vue&type=script&lang=js"
export * from "./testNotes.vue?vue&type=script&lang=js"
import style0 from "./testNotes.vue?vue&type=style&index=0&id=19584766&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "19584766",
  null
  
)

export default component.exports