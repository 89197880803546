var render = function render(){var _vm=this,_c=_vm._self._c;return _c('el-dialog',{staticClass:"history-dialog",attrs:{"visible":_vm.dialogVisible,"width":"800px","close-on-click-modal":false},on:{"update:visible":function($event){_vm.dialogVisible=$event}}},[_c('div',{staticClass:"history-dialog-head"},[_c('div',{staticClass:"history-dialog-flex"},[_c('div',{staticClass:"dialog-head",attrs:{"slot":"title"},slot:"title"},[_vm._v("刷题历史记录")]),_c('el-input',{attrs:{"placeholder":"搜索题号","prefix-icon":"el-icon-search","size":"small"},model:{value:(_vm.searchKey),callback:function ($$v) {_vm.searchKey=$$v},expression:"searchKey"}})],1),_c('div',{staticClass:"pel-table-head"},[_vm._v(" 标注： "),_c('div',{staticClass:"pel-mark"},[_c('div',{staticClass:"is-dot",style:({ backgroundColor: _vm.red })}),_vm._v(" 很难提示 ")]),_c('div',{staticClass:"pel-mark"},[_c('div',{staticClass:"is-dot",style:({ backgroundColor: _vm.orange })}),_vm._v(" 特别注意 ")]),_c('div',{staticClass:"pel-mark"},[_c('div',{staticClass:"is-dot",style:({ backgroundColor: _vm.green })}),_vm._v(" 轻松跳过 ")])])]),_c('el-table',{staticClass:"his-table",staticStyle:{"width":"100%"},attrs:{"data":_vm.historyData,"default-sort":{ prop: 'date', order: 'descending' },"size":"mini"}},[_c('el-table-column',{attrs:{"prop":"questionName","label":"LeetCode题目"}}),_c('el-table-column',{attrs:{"prop":"difficulty","label":"题目难度","formatter":_vm.formatter,"align":"center","width":"180px"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-dropdown',{on:{"command":_vm.handleCommand}},[_c('span',{staticClass:"el-dropdown-link"},[_c('div',{staticClass:"is-dot",style:({
                backgroundColor:
                  scope.row.difficulty == 1
                    ? _vm.green
                    : scope.row.difficulty == 2
                    ? _vm.orange
                    : _vm.red,
              })}),_c('i',{staticClass:"el-icon-arrow-down el-icon--right"})]),_c('el-dropdown-menu',{attrs:{"slot":"dropdown"},slot:"dropdown"},[_c('el-dropdown-item',{attrs:{"command":_vm.beforeHandleCommand(scope.row, 'hard')}},[_vm._v("很难提示")]),_c('el-dropdown-item',{attrs:{"command":_vm.beforeHandleCommand(scope.row, 'normal')}},[_vm._v("特别注意")]),_c('el-dropdown-item',{attrs:{"command":_vm.beforeHandleCommand(scope.row, 'easy')}},[_vm._v("轻松跳过")])],1)],1)]}}])})],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.searchKey == ''),expression:"searchKey == ''"}],staticClass:"history-brush"},[_vm._v(" 已刷题数："+_vm._s(_vm.total)+" 题 ")]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.searchKey == '' && _vm.total > 15),expression:"searchKey == '' && total > 15"}],staticClass:"pagination-block"},[_c('el-pagination',{attrs:{"background":"","prev-text":"上一页","next-text":"下一页","layout":"prev, pager, next ","total":_vm.total,"current-page":_vm.currentPage,"page-size":15},on:{"current-change":_vm.getHistoryTestData}})],1),_c('div',{staticClass:"dialog-foot",attrs:{"slot":"footer"},slot:"footer"})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }